:root {
  --primary-color: #007AFF;
  --primary-hover: #0056b3;
  --danger-color: #dc3545;
  --danger-hover: #c82333;
  --success-color: #34c759;
  --warning-color: #ff9500;
  --error-color: #ff3b30;
  --text-primary: #333;
  --text-secondary: #666;
  --border-color: #e1e1e1;
  --background-light: #f8f9fa;
  --white: #fff;
  --shadow-sm: 0 2px 8px rgba(0,0,0,0.1);
  --shadow-md: 0 4px 12px rgba(0,0,0,0.15);
  --radius-sm: 8px;
  --radius-md: 14px;
  --radius-lg: 20px;
  --transition: all 0.2s ease;
}

/* Budget Tracker Main Container */
.budget-tracker {
  max-width: 900px;
  margin: 0 auto;
  padding: 32px 20px;
  position: relative;
  background: var(--white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

/* Budget Header and Quick Add */
.budget-header {
  margin-bottom: 40px;
}

.error-message {
  background-color: rgba(255, 59, 48, 0.1);
  border-left: 4px solid var(--error-color);
  padding: 12px 16px;
  margin-bottom: 20px;
  border-radius: var(--radius-sm);
}

.error-message p {
  margin: 0;
  color: var(--error-color);
  font-size: 14px;
}

.quick-add-section {
  background: var(--white);
  padding: 32px;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  margin: 24px auto;
  max-width: 900px;
  width: 100%;
  transition: var(--transition);
}

.quick-add-section:hover {
  box-shadow: var(--shadow-md);
}

.quick-add-section .expense-form {
  margin: 8px 0;
}

.expense-form-inputs {
  padding: 8px 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}


.expense-form-inputs {
  display: grid;
  grid-template-columns: minmax(200px, 1.5fr) minmax(120px, 1fr) minmax(150px, 1fr) 120px;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.expense-form-inputs input,
.expense-form-inputs .add-button {
  height: 44px;
  font-size: 15px;
  border-radius: var(--radius-sm);
  transition: var(--transition);
  width: 100%;
}

.expense-form-inputs input {
  padding: 8px 16px;
  border: 2px solid var(--border-color);
  background: var(--white);
  color: var(--text-primary);
}

.expense-form-inputs .add-button {
  padding: 0 20px;
  margin: 0;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.expense-form-inputs input:not([type="date"]) {
  font-size: 15px;
}

.expense-form-inputs input[type="date"] {
  font-family: inherit;
  cursor: pointer;
  min-width: 150px;
}

.expense-form-inputs input[type="number"] {
  text-align: right;
  padding-right: 16px;
}

.expense-form-inputs input:hover {
  border-color: var(--primary-color);
}

.quick-add-section input[type="date"] {
  font-family: inherit;
  color: #333;
  cursor: pointer;
}

.quick-add-section input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.quick-add-section input:focus {
  border-color: #007AFF;
  outline: none;
}

.add-button,
.switch-budget-button,
.reset-button,
.timeline-option {
  background: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 0 20px;
  height: 44px;
  border-radius: var(--radius-sm);
  font-size: 14px;
  cursor: pointer;
  transition: var(--transition);
  white-space: nowrap;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  min-width: max-content;
  margin: 0;
}

.add-button:hover,
.switch-budget-button:hover,
.timeline-option:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.reset-button {
  background: var(--danger-color);
}

.reset-button:hover {
  background: var(--danger-hover);
}

/* Action Bar */
.action-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  padding: 0 4px;
}

.action-buttons {
  display: flex;
  gap: 16px;
}

.budget-period {
  background: var(--background-light);
  border-radius: var(--radius-md);
  padding: 20px;
  margin: 24px 0;
  text-align: center;
  color: var(--text-secondary);
  font-size: 15px;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.05);
}

.budget-period strong {
  color: var(--text-primary);
  margin: 0 5px;
  font-weight: 600;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.modal-content {
  background: var(--white);
  padding: 32px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  max-width: 600px;
  width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  animation: modalFadeIn 0.3s ease;
}

/* Budget History Specific Styles */
.budget-history .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid var(--border-color);
}

.budget-history .modal-header h2 {
  margin: 0;
  font-size: 24px;
  color: var(--text-primary);
}

.close-button {
  background: none;
  border: none;
  font-size: 28px;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  transition: var(--transition);
}

.close-button:hover {
  color: var(--text-primary);
  transform: rotate(90deg);
}

.budget-list {
  margin: -8px -16px;
  padding: 8px 16px;
  max-height: 60vh;
  overflow-y: auto;
}

.budget-item {
  background: var(--background-light);
  border-radius: var(--radius-md);
  padding: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: var(--transition);
  border: 2px solid transparent;
}

.budget-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
  border-color: var(--primary-color);
}

.budget-item.active {
  background: rgba(0, 122, 255, 0.1);
  border-color: var(--primary-color);
}

.budget-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.budget-item-header h3 {
  margin: 0;
  font-size: 18px;
  color: var(--text-primary);
}

.status-badge {
  padding: 4px 12px;
  border-radius: var(--radius-sm);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status-badge.active {
  background: rgba(52, 199, 89, 0.2);
  color: var(--success-color);
}

.status-badge.completed {
  background: rgba(0, 122, 255, 0.2);
  color: var(--primary-color);
}

.status-badge.archived {
  background: rgba(142, 142, 147, 0.2);
  color: var(--text-secondary);
}

.budget-dates {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 14px;
  margin-bottom: 12px;
}

.date-separator {
  color: var(--primary-color);
  font-weight: 600;
}

.budget-amount {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 12px;
}

.budget-progress {
  margin-top: 12px;
}

.progress-bar {
  height: 8px;
  background: var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
}

.progress-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 14px;
  color: var(--text-secondary);
}

.modal-footer {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 2px solid var(--border-color);
  text-align: right;
}

.secondary-button {
  background: var(--background-light);
  color: var(--text-primary);
  border: none;
  padding: 8px 24px;
  border-radius: var(--radius-sm);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.secondary-button:hover {
  background: var(--border-color);
  transform: translateY(-1px);
}

.no-budgets {
  text-align: center;
  padding: 48px 0;
  color: var(--text-secondary);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content h2 {
  margin: 0 0 24px;
  text-align: center;
  color: var(--text-primary);
}

.timeline-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.timeline-option.selected {
  background: var(--success-color);
}

.clear-history {
  background: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 20px 0;
  float: right;
}

.clear-history:hover {
  background: #c82333;
  transform: translateY(-1px);
}

.budget-overview {
  text-align: center;
  margin: 48px 0;
  padding: 32px;
  background: var(--background-light);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.budget-circle {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  box-shadow: var(--shadow-md);
}

.budget-circle-inner {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 2px var(--border-color);
}

.budget-circle-inner h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  background: linear-gradient(135deg, var(--primary-color), var(--success-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.budget-circle-inner p {
  margin: 8px 0 0;
  color: var(--text-secondary);
  font-size: 15px;
  font-weight: 500;
}

.budget-stats {
  display: flex;
  justify-content: center;
  gap: 48px;
  margin-top: 32px;
  padding: 24px;
  background: var(--white);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
}

.stat-item {
  text-align: center;
  flex: 1;
  max-width: 200px;
}

.stat-item label {
  display: block;
  color: var(--text-secondary);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}

.stat-item h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: var(--text-primary);
}

.expense-list {
  margin-top: 48px;
  padding: 32px;
  background: var(--white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.expense-list h3 {
  margin: 0 0 24px;
  font-size: 20px;
  color: var(--text-primary);
}

.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  margin-bottom: 12px;
  cursor: pointer;
  transition: var(--transition);
  background: var(--white);
}

.expense-item:hover {
  transform: translateX(4px);
  border-color: var(--primary-color);
  box-shadow: var(--shadow-sm);
}

.expense-info {
  flex: 1;
}

.expense-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
}

.expense-info span {
  color: var(--text-secondary);
  font-size: 14px;
  display: block;
  margin-top: 4px;
}

.expense-amount {
  font-weight: 700;
  font-size: 16px;
  color: var(--text-primary);
  background: var(--background-light);
  padding: 8px 16px;
  border-radius: var(--radius-sm);
  min-width: 100px;
  text-align: right;
}

.no-expenses {
  text-align: center;
  padding: 48px 0;
  color: var(--text-secondary);
  font-size: 16px;
  background: var(--background-light);
  border-radius: var(--radius-md);
}

/* Projections Section */
.projections {
  margin-top: 48px;
  padding: 32px;
  background: linear-gradient(135deg, var(--primary-color), var(--success-color));
  border-radius: var(--radius-lg);
  color: var(--white);
  text-align: center;
  box-shadow: var(--shadow-md);
}

.projections h3 {
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.9;
}

.projection-amount {
  font-size: 32px;
  font-weight: 700;
}

.projection-label {
  font-size: 16px;
  opacity: 0.8;
  margin-left: 8px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .budget-tracker {
    padding: 20px 16px;
    margin: 16px;
    border-radius: var(--radius-md);
  }

  .expense-form-inputs {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .expense-form-inputs input,
  .expense-form-inputs .add-button {
    height: 48px; /* Larger touch targets */
    font-size: 16px; /* More readable on mobile */
  }

  .quick-add-section {
    padding: 20px;
    margin: 16px auto;
  }

  .budget-circle {
    width: 200px;
    height: 200px;
  }

  .budget-circle-inner {
    width: 180px;
    height: 180px;
  }

  .budget-stats {
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    padding: 16px;
  }

  .stat-item {
    padding: 16px;
    width: 100%;
    max-width: none;
    background: var(--white);
    border-radius: var(--radius-sm);
    box-shadow: var(--shadow-sm);
  }

  .expense-list {
    padding: 16px;
    margin-top: 24px;
  }

  .expense-item {
    padding: 16px;
    margin-bottom: 12px;
  }

  .expense-amount {
    min-width: 80px;
    padding: 8px 12px;
    font-size: 15px;
  }

  .action-buttons {
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  .action-buttons button {
    width: 100%;
    height: 48px;
  }

  .timeline-options {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .budget-period {
    padding: 16px;
    margin: 16px 0;
    font-size: 14px;
  }

  .add-button,
  .switch-budget-button,
  .reset-button,
  .timeline-option {
    height: 48px;
    font-size: 15px;
  }

  /* Enhanced modal for mobile */
  .modal-content {
    padding: 24px;
    border-radius: var(--radius-md);
    max-height: 90vh;
  }

  .modal-header h2 {
    font-size: 20px;
  }

  .budget-list {
    max-height: 70vh;
  }

  .budget-item {
    padding: 16px;
  }

  .projections {
    padding: 24px;
    margin-top: 24px;
  }

  /* Improved touch areas */
  .close-button {
    padding: 8px;
    margin: -8px;
  }

  .budget-item-header h3 {
    font-size: 16px;
  }
}

/* iPhone-specific fixes */
@media screen and (max-width: 390px) {
  .budget-tracker {
    margin: 12px;
    padding: 16px;
  }

  .expense-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .expense-amount {
    align-self: stretch;
    text-align: center;
  }

  /* Safe area insets */
  .modal-content {
    padding-bottom: max(24px, env(safe-area-inset-bottom));
  }

  .budget-list {
    padding-bottom: env(safe-area-inset-bottom);
  }

  /* Enhanced touch targets */
  .budget-item,
  .expense-item,
  .add-button,
  .switch-budget-button,
  .timeline-option,
  .close-button {
    min-height: 44px;
  }

  /* Improved form inputs */
  .expense-form-inputs input {
    font-size: 16px; /* Prevents iOS zoom on focus */
    padding: 12px 16px;
  }

  /* Better scrolling */
  .budget-list,
  .expense-list {
    -webkit-overflow-scrolling: touch;
  }
}
