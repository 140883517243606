/* All existing CSS up to the relevant section remains unchanged */

:root {
  --primary-color: #6366f1;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --text-color: #333;
  --light-gray: #f5f5f5;
  --dark-gray: #444;
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--light-gray);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

  .logo-link {
    height: 48px;
    width: 240px;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
  }

  .app-logo {
    height: 100%;
    width: 100%;
    margin-right: 2rem;
    position: relative;
  }

  .app-logo path,
  .app-logo circle,
  .app-logo line {
    stroke: var(--primary-color);
  }

  .app-logo text {
    fill: var(--primary-color);
  }

  /* User Info Styles */
  .user-info.desktop-only {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 1rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: auto;
  }

  .mobile-menu-user-info {
    display: none;
  }

  .signed-in-text {
    font-size: 0.9rem;
    color: var(--dark-gray);
    white-space: nowrap;
  }

  .logout-button {
    height: 36px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
    border: 1px solid rgba(99, 102, 241, 0.3);
    color: var(--primary-color);
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    width: auto;
  }

  .logout-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.15);
    border-color: var(--primary-color);
  }

  .minimal-button {
    height: 36px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
    border: 1px solid rgba(99, 102, 241, 0.3);
    color: var(--primary-color);
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

/* Mobile Styles */
@media (max-width: 768px) {
  /* Mobile Logo */
  .logo-link {
    height: 42px;
    width: 160px;
    min-width: unset;
    margin-right: 0;
    overflow: visible;
  }

  .app-logo {
    height: 100%;
    width: 160px;
    margin: 0;
  }

  /* Mobile Navigation */
  .nav-container {
    margin-left: auto;
  }

  /* Mobile User Info */
  .mobile-menu-user-info {
    margin-top: 1rem;
    padding: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .mobile-menu-user-info .user-info {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }

  .mobile-menu-user-info .signed-in-text {
    font-size: 0.9rem;
    color: var(--dark-gray);
  }

  .mobile-menu-user-info .logout-button {
    height: 44px;
    width: 100%;
    justify-content: center;
    background: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 0.9rem;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .mobile-menu-user-info .logout-button:hover {
    background: var(--primary-color);
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.15);
  }
  
  /* Enhanced touch targets and spacing for mobile */
  .App-header {
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
  }

  .nav-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .hamburger-menu {
    width: 44px;
    height: 44px;
    padding: 10px;
    margin-left: 0.5rem;
    border-radius: 8px;
    position: relative;
    z-index: 1001;
  }

  /* Hide desktop user info */
  .user-info.desktop-only {
    display: none;
  }

  .nav-container {
    flex: 1;
    margin-left: auto;
  }

  .menu-divider {
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 8px 0;
  }

  .mobile-only {
    display: none;
  }

  nav ul.active .mobile-only {
    display: block;
  }

  .App-header nav ul.active {
    max-height: calc(100vh - 70px - env(safe-area-inset-top));
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .App-header nav ul.active li {
    opacity: 0;
    animation: fadeIn 0.2s ease forwards;
  }

  .App-header nav ul.active li:nth-child(1) { animation-delay: 0.05s; }
  .App-header nav ul.active li:nth-child(2) { animation-delay: 0.1s; }
  .App-header nav ul.active li:nth-child(3) { animation-delay: 0.15s; }
  .App-header nav ul.active li:nth-child(4) { animation-delay: 0.2s; }
  .App-header nav ul.active li:nth-child(5) { animation-delay: 0.25s; }
  .App-header nav ul.active li:nth-child(6) { animation-delay: 0.3s; }
  .App-header nav ul.active li:nth-child(7) { animation-delay: 0.35s; }
  .App-header nav ul.active li:nth-child(8) { animation-delay: 0.4s; }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-8px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .App-header nav ul li:not(.mobile-only) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .App-header nav ul li:last-child {
    border-bottom: none;
  }

  .menu-divider {
    margin: 8px 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }

  .signed-in-mobile {
    color: var(--text-secondary);
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .mobile-logout-button {
    margin: 8px 16px;
    width: calc(100% - 32px);
  }

  .hamburger-menu span {
    width: 24px;
  }

  /* Improved mobile navigation */
  .App-header nav ul {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    padding: 1rem;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .App-header nav ul a {
    padding: 1rem;
    display: block;
    font-size: 16px;
  }

  /* Ensure content doesn't overflow */
  main {
    padding: 1rem;
    margin: 1rem auto;
  }

  /* Adjust dashboard layout */
  .dashboard-metrics {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .metric-card {
    padding: 1.25rem;
  }

  /* Optimize charts for mobile */
  .chart-section {
    padding: 1.25rem;
    margin-bottom: 1rem;
  }

  .chart-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .chart-controls {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
  }

  .return-rate-control {
    min-width: unset;
    width: 100%;
  }
}

/* Add iPhone-specific fixes */
@media screen and (max-width: 390px) {
  :root {
    --vh: 1vh;
  }

  .App {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .App-header {
    position: sticky;
    -webkit-position: sticky;
    top: env(safe-area-inset-top);
    padding-top: max(0.75rem, env(safe-area-inset-top));
  }

  main {
    padding-bottom: env(safe-area-inset-bottom);
  }

  /* Prevent overscrolling issues */
  body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* Enhance touch targets */
  .minimal-button,
  .App-header nav ul a,
  .metric-card,
  .chart-section button {
    min-height: 44px;
    padding: 12px 16px;
  }
}

.App-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.85));
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
}

nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2rem;
}

.nav-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media (max-width: 768px) {
  .App-header {
    padding: 0.75rem 1rem;
  }

  nav {
    margin-left: 1rem;
  }

  .nav-container {
    gap: 1rem;
  }

  /* Clean up duplicate styles */
  .desktop-user-info {
    display: none;
  }

  .nav-container {
    flex: 1;
    margin-left: auto;
  }

  .user-info-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.98);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
  }

  .signed-in-mobile {
    font-size: 0.9rem;
    color: var(--dark-gray);
    margin-bottom: 0.5rem;
  }

  .mobile-logout-button {
    width: 100%;
    margin: 0;
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

.App-header h1 {
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  margin-right: auto;
  letter-spacing: -0.025em;
}

.App-header a {
  text-decoration: none;
  color: inherit;
}

.App-header nav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
}

.App-header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
  justify-content: flex-end;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 1rem;
  order: 2;
  z-index: 2;
}

.hamburger-menu span {
  width: 30px;
  height: 3px;
  background: var(--dark-gray);
  border-radius: 3px;
  transition: all 0.15s ease;
}

.hamburger-menu.active span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.hamburger-menu.active span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.active span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

  @media (max-width: 1024px) {
    .App-header {
      padding: 1rem;
    }

    .hamburger-menu {
      display: flex;
    }

    .App-header nav {
      position: relative;
    }
    
    .App-header nav ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      flex-direction: column;
      background: rgba(255, 255, 255, 0.98);
      backdrop-filter: blur(10px);
      padding: 1rem;
      box-shadow: var(--box-shadow);
      gap: 0.5rem;
      border-top: 1px solid #eee;
      transition: all 0.15s ease;
      z-index: 1;
    }

    .App-header nav ul.active {
      display: flex;
      animation: slideDown 0.15s ease;
    }

    .App-header nav ul.active li {
      opacity: 0;
      animation: fadeIn 0.2s ease forwards;
    }
    
    .App-header nav ul.active .mobile-menu-user-info {
      opacity: 0;
      animation: fadeIn 0.2s ease forwards;
      animation-delay: 0.45s;
    }

  /* Mobile Navigation */
  .user-info.desktop-only {
    display: none;
  }

  .mobile-menu-user-info {
    display: block;
    margin-top: 1rem;
    padding: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.98);
  }

  .mobile-menu-user-info .user-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .mobile-menu-user-info .signed-in-text {
    font-size: 0.9rem;
    color: var(--dark-gray);
    margin-bottom: 0.5rem;
  }

  .mobile-menu-user-info .logout-button {
    width: 100%;
    justify-content: center;
    padding: 0.75rem;
    font-size: 0.9rem;
    background: white;
    border: 1px solid var(--primary-color);
  }

  .mobile-menu-user-info .logout-button:hover {
    background: var(--primary-color);
    color: white;
  }

  .App-header nav ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background: white;
    padding: 1rem;
    box-shadow: var(--box-shadow);
    gap: 0.5rem;
    border-top: 1px solid #eee;
    transition: all 0.15s ease;
    z-index: 1;
  }

  .App-header nav ul.active {
    display: flex;
    animation: slideDown 0.15s ease;
  }

  .App-header nav ul li {
    width: 100%;
    text-align: left;
  }

  .App-header nav ul a {
    display: block;
    padding: 0.5rem 0;
    border-bottom: none;
  }

  .user-info {
    margin-right: 1rem;
    border-left: none;
    padding-left: 0;
    order: 1;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.App-header nav a, 
.App-header nav a:visited,
.App-header nav a:link {
  color: var(--dark-gray);
  text-decoration: none !important;
  padding: 0.5rem 0;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.App-header nav a:hover {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

/* Desktop user info */
.desktop-user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  margin-left: auto;
}

.signed-in-text {
  white-space: nowrap;
  color: var(--dark-gray);
  font-size: 0.9rem;
}

.minimal-button {
  height: 36px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  border: 1px solid rgba(99, 102, 241, 0.3);
  border-radius: 8px;
  color: var(--primary-color);
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}


.minimal-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.15);
  border-color: var(--primary-color);
}

main {
  flex: 1;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
  width: 100%;
}

.App-footer {
  background-color: var(--dark-gray);
  color: white;
  text-align: center;
  padding: 1.5rem;
  margin-top: 2rem;
}

.dashboard {
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.dashboard-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0 3rem;
}

.metric-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  padding: 1.75rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  text-decoration: none !important;
  color: inherit;
  position: relative;
  overflow: hidden;
}

.metric-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 4px 4px 0 0;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.metric-card h3 {
  font-size: 1.15rem;
  font-weight: 700;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.75rem;
  letter-spacing: -0.025em;
}

.dashboard-charts-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.chart-row {
  width: 100%;
}

.chart-section {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.chart-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 4px 4px 0 0;
}

.chart-section:hover {
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.chart-section.full-width {
  width: 100%;
}

.chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.chart-header h3 {
  font-size: 1.4rem;
  font-weight: 700;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  letter-spacing: -0.025em;
}

.chart-controls {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.return-rate-control {
  min-width: 300px;
}

.return-rate-control label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--dark-gray);
  font-weight: 500;
}

.return-rate-control label span {
  color: var(--primary-color);
  font-weight: 600;
}

.slider-container {
  position: relative;
  padding: 1rem 0;
}

.return-rate-slider {
  width: 100%;
  height: 6px;
  background: #e2e8f0;
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
}

.return-rate-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.return-rate-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.2);
}

.slider-markers {
  position: relative;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #64748b;
}

.slider-markers {
  position: relative;
  margin-top: 0.75rem;
  height: 0;
}

.slider-marker {
  position: absolute;
  transform: translateX(-50%);
  white-space: nowrap;
  background: #f1f5f9;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.875rem;
}

.slider-marker.historical {
  color: #6366f1;
}

.slider-marker.conservative {
  color: #10b981;
}

.chart-legend {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--dark-gray);
  font-size: 0.875rem;
}

.line-style {
  display: inline-block;
  width: 20px;
  height: 2px;
  background: currentColor;
}

.line-style.dashed {
  background: none;
  border-top: 2px dashed var(--primary-color);
  height: 0;
}

.line-style.solid {
  background: var(--primary-color);
  height: 2px;
}

.chart-loading {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-gray);
  background: #f8fafc;
  border-radius: 8px;
}

/* Calculator Styles */
.retirement-calculator,
.housing-calculator,
.investment-tracker {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 2rem;
}

/* Modern Page Title Styles */
.retirement-calculator h2,
.housing-calculator h2,
.investment-tracker h2 {
  font-size: 2.5rem;
  font-weight: 800;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  letter-spacing: -0.025em;
  line-height: 1.2;
  position: relative;
  display: inline-block;
}

.retirement-calculator h2::after,
.housing-calculator h2::after,
.investment-tracker h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 2px;
}

.investment-tracker > p {
  color: #4a5568;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.calculator-description {
  color: var(--dark-gray);
  margin-bottom: 2rem;
}

.calculator-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.7);
  position: relative;
  overflow: hidden;
}

.calculator-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 4px 4px 0 0;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.card-header:hover {
  background-color: var(--light-gray);
}

.card-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--dark-gray);
}

.expand-toggle {
  color: var(--dark-gray);
}

.card-content {
  padding: 0 1.5rem 1.5rem;
}

.input-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-gray);
  margin-bottom: 0.5rem;
}

.input-group input,
.input-group select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.input-group input:focus,
.input-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1);
}

.card-style {
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #eee;
}

.summary-card {
  text-align: center;
}

.summary-card .result-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 1rem 0;
}

.summary-card .result-note {
  color: var(--dark-gray);
  margin-bottom: 1.5rem;
}

.data-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
}

.summary-item {
  text-align: center;
}

.summary-item label {
  display: block;
  color: var(--dark-gray);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.summary-item value {
  display: block;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1.1rem;
}

.minimal-action-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 20px;
  background-color: white;
  color: var(--primary-color);
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.minimal-action-button:hover {
  border-color: var(--primary-color);
  background-color: #f5f7ff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
}

.account-action-buttons {
  display: flex;
  gap: 12px;
  margin-top: 24px;
  justify-content: center;
}

/* Investment Tracker Styles */
.investment-tracker {
  max-width: 1000px;
  margin: 0 auto;
}

.portfolio-summary {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 16px;
  margin: 2.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
}

.portfolio-summary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 4px 4px 0 0;
}

.summary-total {
  flex: 1;
  min-width: 200px;
  padding: 0.5rem;
}

.summary-total h3 {
  font-size: 1.4rem;
  font-weight: 700;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.25rem;
  letter-spacing: -0.025em;
}

.total-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 10px 0;
}

.summary-allocation {
  flex: 2;
  min-width: 300px;
}

.allocation-chart {
  height: 30px;
  background-color: #f8f9fa;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  margin: 15px 0;
}

.allocation-bar {
  height: 100%;
  transition: width 0.5s ease;
}

.allocation-legend {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 6px 16px;
  border-right: 1px solid #e2e8f0;
}

.legend-item:last-child {
  border-right: none;
}

.legend-color {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 2px;
}

.legend-label {
  margin-right: 4px;
  color: #4a5568;
}

.legend-value {
  font-weight: 600;
  color: #2d3748;
}

.investment-accounts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.investment-account-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  padding: 24px;
  transition: all 0.15s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.7);
  height: auto;
  overflow: hidden;
  will-change: max-height;
}

.investment-account-card.collapsed {
  height: 72px; /* Header height + padding */
  transition: all 0.15s ease-in-out;
}

.investment-account-card .account-fields,
.investment-account-card .account-projections {
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
  transform-origin: top;
}

.investment-account-card.collapsed .account-fields,
.investment-account-card.collapsed .account-projections {
  opacity: 0;
  pointer-events: none;
}

.investment-account-card:not(.collapsed) .account-fields,
.investment-account-card:not(.collapsed) .account-projections {
  pointer-events: auto;
}

.investment-account-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.account-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  margin: -12px -12px 12px -12px;
  cursor: pointer;
  white-space: nowrap;
  min-width: 0;
}

.account-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #2d3748;
  letter-spacing: -0.025em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-header:hover {
  background-color: #f8f9fa;
}

.account-icon {
  font-size: 24px;
  color: var(--primary-color);
}

.account-value-summary {
  font-weight: 700;
  font-size: 18px;
  color: #2d3748;
  margin-left: auto;
  margin-right: 8px;
}

.account-field {
  margin-bottom: 16px;
}

.account-field label {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 6px;
  display: block;
}

.account-field input {
  width: 100%;
  font-size: 15px;
  padding: 10px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.account-field input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.15);
  outline: none;
}

.account-projections {
  background: rgba(248, 250, 252, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.projection-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #4a5568;
  padding: 8px 0;
}

.projection-value {
  font-weight: 600;
  color: var(--primary-color);
}

/* Menu Styles */
.add-account-menu,
.remove-account-menu {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  padding: 32px;
  margin-top: 24px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  position: relative;
  overflow: hidden;
}

.add-account-menu::before,
.remove-account-menu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 4px 4px 0 0;
}

.add-account-menu h4,
.remove-account-menu h4 {
  font-size: 1.4rem;
  font-weight: 700;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  letter-spacing: -0.025em;
}

.account-type-grid,
.account-selection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  margin-bottom: 20px;
}

.account-type-card,
.account-selection-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.account-type-card::before,
.account-selection-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 3px 3px 0 0;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.account-type-card:hover::before,
.account-selection-card:hover::before {
  opacity: 1;
}

.account-type-card:hover,
.account-selection-card:hover {
  background-color: #f5f7ff;
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
}

.account-name {
  font-weight: 700;
  font-size: 1.15rem;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.025em;
  margin: 8px 0;
}

.account-description {
  font-size: 14px;
  color: #718096;
}

.custom-account-input {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 100%;
  margin: 10px 0;
  transition: all 0.2s ease;
}

.custom-account-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.custom-account-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 20px;
  background-color: white;
  color: var(--primary-color);
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 8px;
}

.custom-account-button:not(:disabled):hover {
  border-color: var(--primary-color);
  background-color: #f5f7ff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
}

.custom-account-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #f8fafc;
}

.close-menu-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 24px;
  background-color: #f8fafc;
  color: #64748b;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  margin: 0 auto;
}

.close-menu-button:hover {
  background-color: #f1f5f9;
  color: #475569;
  transform: translateY(-1px);
}

/* Confirmation Dialog Styles */
dialog.confirmation-dialog {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  padding: 24px;
  max-width: 400px;
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border: none;
  margin: 0;
}

dialog.confirmation-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.confirmation-dialog h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #2d3748;
  margin-bottom: 12px;
}

.confirmation-dialog p {
  font-size: 15px;
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 24px;
}

.confirmation-dialog-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.confirm-button,
.cancel-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.confirm-button {
  background-color: #ef4444;
  color: white;
  border: none;
}

.confirm-button:hover {
  background-color: #dc2626;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
}

.cancel-button {
  background-color: white;
  color: #64748b;
  border: 1px solid #e2e8f0;
}

.cancel-button:hover {
  background-color: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

/* Welcome Page Styles */
.welcome-container {
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7ff 0%, #fff 100%);
}

.welcome-content {
  max-width: 1000px;
  text-align: center;
  padding: 2rem;
}

.welcome-title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.welcome-subtitle {
  font-size: 1.5rem;
  color: var(--dark-gray);
  margin-bottom: 3rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.welcome-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.3rem;
  font-weight: 700;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.75rem;
  letter-spacing: -0.025em;
}

.feature-card p {
  color: #666;
  font-size: 0.9rem;
}

.welcome-cta {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin: 0 1rem;
}

.cta-button.primary {
  background: var(--primary-color);
  color: white;
  box-shadow: 0 4px 14px rgba(99, 102, 241, 0.2);
}

.cta-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(99, 102, 241, 0.3);
}

.cta-button.secondary {
  background: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.cta-button.secondary:hover {
  background: #f5f7ff;
  transform: translateY(-2px);
}

/* Enhanced Auth Styles */
.auth-container {
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7ff 0%, #fff 100%);
}

.auth-card {
  background: white;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  padding: 2rem;
  max-width: 400px;
  width: 90%;
}
