.welcome-container {
  min-height: calc(100vh - 140px); /* Account for header and footer */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.welcome-content {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.welcome-title {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  text-align: center;
}

.welcome-title span.highlight {
  background: linear-gradient(120deg, #4facfe 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: inline;
  vertical-align: baseline;
}

.welcome-description {
  font-size: 1.25rem;
  color: #34495e;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.6;
  text-align: center;
}

.welcome-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.feature-card i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(120deg, #4facfe 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.feature-card p {
  color: #7f8c8d;
  line-height: 1.5;
}

.welcome-actions {
  margin-top: 2rem;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 2rem;
  transition: all 0.3s ease;
  margin: 0 1rem;
}

.btn-primary {
  background: linear-gradient(120deg, #4facfe 0%, #00f2fe 100%);
  color: white;
  box-shadow: 0 4px 15px rgba(79, 172, 254, 0.4);
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(79, 172, 254, 0.6);
}

.btn-secondary {
  background: white;
  color: #4facfe;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.btn-secondary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease forwards;
}

.fade-in-delay {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: 0.5s;
}

.slide-up {
  opacity: 0;
  animation: slideUp 1s ease forwards;
  animation-delay: 0.3s;
}

.slide-up-delay {
  opacity: 0;
  animation: slideUp 1s ease forwards;
  animation-delay: 0.6s;
}

/* Responsive Design */
@media (max-width: 768px) {
  .welcome-container {
    padding: 1rem;
  }
  
  .welcome-content {
    padding: 1rem;
    width: 100%;
  }
  
  .welcome-title {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
    padding: 0;
    word-break: break-word;
    hyphens: auto;
  }

  .welcome-description {
    font-size: 1.1rem;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .welcome-title span.highlight {
    display: inline;
    white-space: normal;
  }

  .welcome-cards {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .btn {
    display: block;
    margin: 1rem auto;
    max-width: 200px;
  }
}
